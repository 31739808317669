import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import commonStyles from 'Groups/stylesParams';
import { groupsListWidgetStylesParams } from 'settings/groups-list-widget';
import { getGroupsListWidgetCssVars } from 'settings/groups-list-widget/cssVars';

export default {
  ...commonStyles,
  ...groupsListWidgetStylesParams,
};

// noinspection JSUnusedGlobalSymbols
export const customCssVars: CustomCssVarsFn = (params) => ({
  ...getGroupsListWidgetCssVars(params),
});
